function importCardsModal() {
    $("#import-cards-modal").modal();
}

function checkUploadFile() {
    var file = $("#import-cards-modal").find(".js-upload-file")[0].files[0];
    console.log("Number of import cards modals: " + $("#import-cards-modal").length);
    console.log($("#import-cards-modal").find(".js-upload-file")[0].files);
    console.log(file);
    if ((file != "undefined") && (file != null) && (file.name.length > 1)) {
        if (file.size > 16000000) {
            flashModal(i18n.gettext("The file is too large."));
            return false;
//        } else if (!(file.name.toLowerCase().endsWith('.xml') || file.name.toLowerCase().endsWith('.html') || file.name.toLowerCase().endsWith('.xls') || file.name.toLowerCase().endsWith('.xlsx') || file.name.toLowerCase().endsWith('.csv') || file.name.toLowerCase().endsWith('.json') || file.name.toLowerCase().endsWith('.docx'))) {
//            flashModal(i18n.gettext("File type is not xml, html, xls, csv or json."));
        } else {
            return true;
        }
    } else {
        flashModal(i18n.gettext("Please select file."));
        return false;
    }
}

function importCards() {
    if (checkUploadFile()) {
        flashModal(i18n.gettext("Please wait..."));
        var boxId = $('#boxId').val();

        var fd = new FormData(document.getElementById("importCardsForm"));
        fd.append("module", "kartensatz_neue_karten");

        $.ajax({
            // retry-sensitive
            url: 'kartensatz-neue-karten-' + boxId, //server script to process data
            type: 'POST',
            xhr: function () {  // custom xhr

                myXhr = $.ajaxSettings.xhr();
                if (myXhr.upload) { // if upload property exists
                    // myXhr.upload.addEventListener('progress', progressHandlingFunction, false); // progressbar
                }
                return myXhr;
            },
            //Ajax events
            success: completeHandler = function (data) {

                /*
                 * workaround for chrome browser // delete the fakepath
                 */
                if (navigator.userAgent.indexOf('Chrome')) {
                    var catchFile = $(":file").val().replace(/C:\\fakepath\\/i, '');
                } else {
                    var catchFile = $(":file").val();
                }

                // load module

                if ($('#statistik-content-container').length > 0) {
                    $('#statistik-content-container').html(data);
                } else if ($('.cardset-info').length > 0) {
                    $('.cardset-info').html(data);
                } else if ($('#cardset-content').length > 0) {
                    $('#cardset-content').html(data);
                } else if ($("#colearners-content").length > 0) {
                    $("#colearners-content").html(data);
                } else if ($(".kartensatz-diskussionen").length > 0) {
                    $(".kartensatz-diskussionen").html(data);
                } else if ($('html').hasClass("page-kartensatz-einstellungen")) {
                    $('.kartensatz-einstellungen').html(data);
                } else if ($('.explanation').length > 0) {
                    $('.explanation').html(data);
                } else if ($('#firstCardsetActions').length > 0) {
                    $('#firstCardsetActions').html(data);
                } else {
                    debug("Unclear where to put data.");
                }

                closeModal();

            },
            error: errorHandler = function () {
                flashModal(i18n.gettext("Error while processing the upload."));
            },
            // Form data
            data: fd,
            //Options to tell JQuery not to process data or worry about content-type
            cache: false,
            contentType: false,
            processData: false
        }, 'json');
    }
}

function importCardsFromPaste() {

    var pastedValue = $("#import-paste-area").val();
    
    var splitCellCharSelection = $('input[name="splitCellChar"]:checked').val();
    debug("splitCellCharSelection: " + splitCellCharSelection);
    
    var splitCellChar = "\t";
    if (splitCellCharSelection === "userdefined") {
        splitCellChar = $("#userDefinedSplitCellChar").val();
    } else if (splitCellCharSelection === "comma") {
        splitCellChar = ",";
    }
    
    var splitLineCharSelection = $('input[name="splitLineChar"]:checked').val();
    debug("splitLineCharSelection: " + splitLineCharSelection);
    
    var splitLineChar = "\n";
    if (splitLineCharSelection == "userdefined") {
        splitLineChar = $("#userDefinedSplitLineChar").val();
    } else if (splitLineCharSelection === "semicolon") {
        splitLineChar = ";";
    }

    debug("Pasted: " + pastedValue);

    var cardStrings = pastedValue.split(splitLineChar);

    console.log(cardStrings);

    shortInfo(i18n.gettext("Processing..."));

    $("#preview-block").show();

    if (cardStrings.length > 0) {
        var newCards = "";
        
        shortInfo(i18n.gettext("Updating preview below...") + " <blink>(" + i18n.gettext("Scroll down to view!") + ")</blink>");

        for (let i = 0; i < cardStrings.length; i++) {
            var cardString = cardStrings[i];
            var card = cardString.split(splitCellChar);

            if (typeof card[0] !== "undefined" && card[0].trim() !== "" && card[0].trim() !== "undefined" && typeof card[1] !== "undefined" && card[1].trim() !== "" && card[1].trim() !== "undefined") {

                newCards += '<div class="card newcard w100 clearfix" id="card-' + i + '">';

                newCards += '<div class="question-bg"></div>';
                newCards += '<div class="question" style="height: auto;">'
                newCards += '<div class="editor-new-card-question input editable w100" id="newQuestion-' + i + '" contenteditable="true" tabindex="40">';
                
                if (splitLineChar.indexOf("\n") === -1) {
                    card[0] = card[0].replaceAll("\n", "<br>");
                }
                
                newCards += card[0];
                newCards += '</div></div>';

                newCards += '<div class="answer-bg"></div>';
                newCards += '<div class="answer clearfix" style="height: auto;">';

                console.log(card);

                if (typeof card[2] !== undefined && typeof card[3] !== undefined && (card[2].trim() === 'correct' || card[2].trim() === 'wrong')) {
                    newCards += '<ul class="mc-option-list" style="display:block;">';

                    var mchoiceIndex = 2;
                    
                    while (typeof card[mchoiceIndex] !== undefined && typeof card[mchoiceIndex + 1] !== undefined && (card[mchoiceIndex].trim() === 'correct' || card[mchoiceIndex].trim() === 'wrong')) {
                        var j = mchoiceIndex / 2;
                        newCards += '<li id="newMultipleChoiceAnswer-' + i + '-' + j + '">';
                        newCards += '<div class="choice-container">';
                        newCards += '<div class="custom-checkbox">';
                        newCards += '<input name="cardcheckbox' + i + '" type="checkbox" class="mchoice-checkbox" ';
                        if (card[mchoiceIndex].trim() === "correct") {
                            newCards += 'checked="checked"';
                        }
                        newCards += '>';
                        newCards += '</div>';
                        newCards += '<div class="mc-option-text input editable" contenteditable="true">';
                        
                        if (splitLineChar.indexOf("\n") === -1) {
                            card[mchoiceIndex + 1] = card[mchoiceIndex + 1].replaceAll("\n", "<br>");
                        }
                        
                        newCards += card[mchoiceIndex + 1];
                        
                        newCards += '</div>';
                        newCards += '</div>';
                        newCards += '</li>';

                        mchoiceIndex += 2;
                    }

                    newCards += '</ul>';
                }

                newCards += '<div class="editor-new-card-answer input editable w100" id="newAnswer-' + i + '" contenteditable="true" tabindex="40">';
                
                if (splitLineChar.indexOf("\n") === -1) {
                    card[1] = card[1].replaceAll("\n", "<br>");
                }
                
                newCards += card[1];
                newCards += '</div></div>';

                newCards += '</div>';

                newCards += '<br/><br/>';
            }
        }

        $("#preview-area").html(newCards);
        
        // var elmnt = document.getElementById("preview-block");
        // elmnt.scrollIntoView(true);
        
        $(".js-save-all-cards-btn").show();
        $(".js-select-categories").show();
        $(".js-save-all-cards-btn:last").hide();
    } else {
        $("#preview-area").html(i18n.gettext("There are no data which can be shown by preview."));
        $(".js-save-all-cards-btn").hide();
        $(".js-select-categories").hide();
    }

}
